<template>
	<v-snackbar bottom color="warning" right :timeout="0" :value="!isOnLine">
		<v-layout align-center row justify-center>
			<v-icon class="mr-2">wifi_off</v-icon>
			<v-flex v-t="'connection_lost'" shrink />
		</v-layout>
	</v-snackbar>
</template>

<script>
export default {
	name: 'ConnectionChecker',
	data: function () {
		return {
			isOnLine: navigator.onLine
		}
	},
	mounted: function () {
		window.addEventListener(
			'offline',
			function () {
				this.isOnLine = false
			}.bind(this)
		)
		window.addEventListener(
			'online',
			function () {
				this.isOnLine = true
			}.bind(this)
		)
	}
}
</script>
